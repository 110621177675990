var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.transactions,"sort-by":_vm.sortBy,"must-sort":true,"sort-desc":true,"server-items-length":_vm.count,"headers":_vm.headers,"hide-default-footer":_vm.dense,"hide-default-header":_vm.dense,"item-class":_vm.rowClasses,"footer-props":{
        itemsPerPageOptions: [10, 25, 100]
    },"item-key":"hash"},on:{"update:options":_vm.onPagination},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No transactions found ")]},proxy:true},{key:"item.hash",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'succeeded'),expression:"txStatus(item) == 'succeeded'"}],staticClass:"mr-2",attrs:{"small":"","color":"success lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")]),_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'failed'),expression:"txStatus(item) == 'failed'"}],staticClass:"mr-2",attrs:{"small":"","color":"error lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")]),_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'unknown'),expression:"txStatus(item) == 'unknown'"}],staticClass:"mr-2",attrs:{"small":"","color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")]),_c('v-progress-circular',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'syncing'),expression:"txStatus(item) == 'syncing'"}],staticClass:"mr-2",attrs:{"size":"16","width":"2","indeterminate":"","color":"primary"}},'v-progress-circular',attrs,false),on))]}}],null,true)},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'succeeded'),expression:"txStatus(item) == 'succeeded'"}]},[_vm._v("Succeeded Transaction")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'failed'),expression:"txStatus(item) == 'failed'"}]},[_vm._v("Failed Transaction")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'unknown'),expression:"txStatus(item) == 'unknown'"}]},[_vm._v("Unkown Transaction Status")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.txStatus(item) == 'syncing'),expression:"txStatus(item) == 'syncing'"}]},[_vm._v("Indexing Transaction...")])]),_c('Hash-Link',{attrs:{"type":'transaction',"hash":item.hash}})]}},{key:"item.method",fn:function(ref){
    var item = ref.item;
return [(item.methodDetails && Object.keys(item.methodDetails).length)?_c('v-tooltip',{attrs:{"top":"","open-delay":150,"color":"grey darken-1","content-class":"tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"primary lighten-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"color--text methodName"},[_vm._v(_vm._s(_vm.getMethodName(item)))])])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(_vm.getMethodLabel(item.methodDetails)))])]):_c('span',[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.getMethodName(item)),expression:"getMethodName(item)"}],attrs:{"label":"","small":"","color":"color--text primary lighten-1"}},[_vm._v(_vm._s(_vm.getMethodName(item)))])],1)]}},{key:"item.timestamp",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"my-2 text-left"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(item.timestamp),'MM/DD h:mm:ss A'))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.moment(item.timestamp).fromNow()))])])]}},{key:"item.from",fn:function(ref){
    var item = ref.item;
return [(_vm.dense)?[_c('div',{staticClass:"my-2 text-left"},[_vm._v(" From: "),_c('Hash-Link',{attrs:{"type":'address',"hash":item.from}}),_c('br'),(item.to)?_c('span',[_vm._v("To: "),_c('Hash-Link',{attrs:{"type":'address',"hash":item.to,"withTokenName":true,"withName":true}})],1):(item.receipt && item.receipt.contractAddress)?_c('span',[_vm._v("Created: "),_c('Hash-Link',{attrs:{"type":'address',"hash":item.receipt.contractAddress,"withTokenName":true,"withName":true}})],1):_vm._e()],1)]:[(item.from && item.from === _vm.currentAddress)?_c('v-chip',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("self")]):_vm._e(),_c('Hash-Link',{attrs:{"type":'address',"hash":item.from}})]]}},{key:"item.blockNumber",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":'/block/' + item.blockNumber}},[_vm._v(_vm._s(item.blockNumber))])]}},{key:"item.to",fn:function(ref){
    var item = ref.item;
return [(item.to && item.to === _vm.currentAddress)?_c('v-chip',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("self")]):_vm._e(),_c('Hash-Link',{attrs:{"type":'address',"hash":item.to,"withTokenName":true,"withName":true}})]}},{key:"item.value",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromWei")(item.value,'ether', _vm.chain.token))+" ")]}},{key:"item.fee",fn:function(ref){
    var item = ref.item;
return [(item.receipt)?_c('span',[_vm._v(_vm._s(_vm._f("fromWei")(item.gasPrice * (item.gas || item.receipt.gasUsed),'ether', _vm.chain.token)))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }