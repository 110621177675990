var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isUserAdmin)?_c('Add-Account-Modal',{ref:"addAccountModalRef"}):_vm._e(),(_vm.isUserAdmin)?_c('Unlock-Account-Modal',{ref:"openUnlockAccountModalRef"}):_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"no-data-text":"No Accounts","items":_vm.accounts,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.accountCount,"footer-props":{
            itemsPerPageOptions: [10, 25, 100]
        },"headers":_vm.headers},on:{"update:options":_vm.getAccounts},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No Accounts Available ")]},proxy:true},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.privateKey),expression:"item.privateKey"}]},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Account has been unlocked with private key.")])]),_c('Hash-Link',{attrs:{"type":'address',"hash":item.address}})]}},{key:"top",fn:function(){return [(_vm.isUserAdmin)?_c('v-toolbar',{staticClass:"py-0",attrs:{"flat":"","dense":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"id":"resyncAllAccounts","disabled":_vm.loading,"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.syncAccounts()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-sync")]),_vm._v("Resync ")],1)]}}],null,false,862936618)},[_vm._v(" This will send a request with the 'eth_accounts' method to the RPC server, and add returned addresses to your accounts list. ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.openAddAccountModal()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Add Account ")],1)],1):_vm._e()]},proxy:true},{key:"item.balance",fn:function(ref){
        var item = ref.item;
return [(item.balance)?_c('span',[_vm._v(" "+_vm._s(_vm._f("fromWei")(item.balance,'ether', _vm.chain.token))+" ")]):_c('span',[_vm._v("N/A")])]}},(_vm.isUserAdmin)?{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openUnlockAccountModal(item)}}},[_vm._v("Set Private Key")])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }