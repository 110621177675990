var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isExplorerSectionAvailable)?[_c('h2',[_vm._v("Explorer")]),_c('v-row',{staticClass:"mt-2"},[(_vm.syncStatus || _vm.loading)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-alert',{class:(_vm.explorerClass + "--text"),attrs:{"type":_vm.explorerClass,"border":"left","colored-border":"","elevation":"2"}},[_c('small',{staticStyle:{"position":"absolute"}},[_vm._v("Sync Status")]),_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1241205831)},[(_vm.syncStatus == 'healthy')?[_vm._v("Syncing is working properly. Blocks & transactions should appear in near real-time in the explorer.")]:_vm._e(),(_vm.syncStatus == 'recovering')?[_vm._v("Syncing is not working properly. We are working on getting things back up. In the meantime, blocks won't be synced in real-time but batched every 5 minutes.")]:_vm._e()],2)],1),(_vm.syncStatus)?_c('div',{staticClass:"mt-4 text-h4 text-center",staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.syncStatus))]):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)],1):_vm._e(),(_vm.isLatestCheckedBlockAvailable && _vm.isStartingBlockAvailable || _vm.loading)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-alert',{staticClass:"primary--text",attrs:{"border":"left","colored-border":"","elevation":"2"}},[_c('small',{staticStyle:{"position":"absolute"}},[_vm._v("Block Integrity")]),_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1241205831)},[_vm._v(" The explorer is regularly checking that all blocks are accounted for, and that none are missing."),_c('br'),_vm._v(" This means that we can guarantee that all blocks between #"+_vm._s(_vm.startingBlock)+" and #"+_vm._s(_vm.latestCheckedBlock)+" have been indexed successfully as well as their transactions. ")])],1),(_vm.startingBlock !== null && _vm.latestCheckedBlock)?_c('div',{staticClass:"mt-4 text-h4 text-center"},[_vm._v(_vm._s(_vm.startingBlock)+" -> "+_vm._s(_vm.latestCheckedBlock))]):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)],1):_vm._e(),(_vm.latestCheckedAt || _vm.loading)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-alert',{staticClass:"primary--text",attrs:{"border":"left","colored-border":"","elevation":"2"}},[_c('small',[_vm._v("Latest Check")]),(_vm.latestCheckedAt)?_c('div',{staticClass:"mt-4 text-h4 text-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(_vm.latestCheckedAt),'h:mma'))),_c('br')]):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)],1):_vm._e()],1)]:_vm._e(),(_vm.isRpcSectionAvailable)?[_c('h2',[_vm._v("RPC")]),_c('v-row',{staticClass:"mt-2"},[(_vm.isRpcReachable !== null && _vm.isRpcReachable !== undefined || _vm.loading)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-alert',{class:(_vm.rpcClass + "--text"),attrs:{"type":_vm.rpcClass,"border":"left","colored-border":"","elevation":"2"}},[_c('small',{staticStyle:{"position":"absolute"}},[_vm._v("Status")]),_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1241205831)},[(_vm.isRpcReachable)?[_vm._v("We are able to query the RPC endpoint for new blocks.")]:[_vm._v(" We are not able to query the RPC endpoint, new blocks won't be indexed. ")]],2)],1),(_vm.rpcStatus)?_c('div',{staticClass:"mt-4 text-h4 text-center",staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.rpcStatus))]):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)],1):_vm._e(),(_vm.rpcHealthCheckedAt || _vm.loading)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-alert',{staticClass:"primary--text",attrs:{"border":"left","colored-border":"","elevation":"2"}},[_c('small',[_vm._v("Latest Check")]),(_vm.rpcHealthCheckedAt)?_c('div',{staticClass:"mt-4 text-h4 text-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(_vm.rpcHealthCheckedAt),'h:mm:ssa'))),_c('br')]):_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)],1):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }