var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"sort-by":_vm.sortBy,"must-sort":true,"sort-desc":true,"server-items-length":_vm.count,"hide-default-header":_vm.dense,"item-key":"id","items":_vm.transfers},on:{"update:options":_vm.onPagination},scopedSlots:_vm._u([(!_vm.dense)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-switch',{attrs:{"label":"Unformatted Amounts"},model:{value:(_vm.unformatted),callback:function ($$v) {_vm.unformatted=$$v},expression:"unformatted"}})],1)]},proxy:true}:null,{key:"item.transactionHash",fn:function(ref){
var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'transaction',"hash":item.transaction.hash}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(_vm.type[item.token])?_c('v-chip',{staticClass:"success mr-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.formatContractPattern(_vm.type[item.token]))+" ")]):_c('span',[_vm._v("N/A")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 text-left"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(item.transaction.timestamp),'MM/DD h:mm:ss A'))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.moment(item.transaction.timestamp).fromNow()))])])]}},{key:"item.blockNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/block/' + item.transaction.blockNumber}},[_vm._v(_vm._s(item.transaction.blockNumber))])]}},{key:"item.src",fn:function(ref){
var item = ref.item;
return [(item.src === _vm.address)?_c('v-chip',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("self")]):_vm._e(),_c('Hash-Link',{attrs:{"type":'address',"hash":item.src,"fullHash":!_vm.dense,"withName":true,"withTokenName":true}})]}},{key:"item.dst",fn:function(ref){
var item = ref.item;
return [(item.dst === _vm.address)?_c('v-chip',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("self")]):_vm._e(),_c('Hash-Link',{attrs:{"type":'address',"hash":item.dst,"fullHash":!_vm.dense,"withName":true,"withTokenName":true}})]}},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'address',"hash":item.token,"withName":true,"withTokenName":true,"tokenId":item.tokenId}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromWei")(item.amount,_vm.decimals[item.token], _vm.symbols[item.token], _vm.unformatted))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }