var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.length > 1)?_c('div',{staticClass:"chart"},[_c('div',{ref:"tooltip",attrs:{"id":"tooltip","role":"tooltip"}},[(_vm.tooltipData)?[_vm._v(" "+_vm._s(this.xLabels[_vm.tooltipData.index])+" - "+_vm._s(parseInt(this.data[_vm.tooltipData.index]).toLocaleString())+" "+_vm._s(_vm.formattedTooltipUnit)+" ")]:_vm._e(),_c('div',{attrs:{"id":"arrow","data-popper-arrow":""}})],2),_c('TrendChart',{ref:"chart",staticClass:"trend-chart",attrs:{"interactive":true,"datasets":[
                {
                    data: _vm.data,
                    smooth: true,
                    fill: true,
                    className: 'curve'
                }
            ],"grid":{
                verticalLines: false,
                horizontalLines: true
            },"labels":{
                xLabels: _vm.xLabels,
                yLabels: 5,
                yLabelsTextFormatter: function (val) { return parseInt(val).toLocaleString(); }
            },"min":0},on:{"mouse-move":_vm.onMouseMove}})],1):_c('div',{staticClass:"disabled-chart-container"},[_c('h6',{staticClass:"disabled-chart-title"},[_vm._v("Not enough data to display graph.")]),_c('TrendChart',{staticClass:"disabled-chart",attrs:{"datasets":[
                {
                    data: [500, 10000, 5000],
                    smooth: true,
                    fill: true,
                    className: 'curve'
                }
            ],"grid":{
                verticalLines: false,
                horizontalLines: true
            },"labels":{
                xLabels: ['09/01', '09/02', '09/03'],
                yLabels: 3,
            },"min":0}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }