var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.logs,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.logCount,"headers":_vm.headers,"footer-props":{
            itemsPerPageOptions: [10, 25, 100]
        },"item-key":"id"},on:{"update:options":_vm.onPagination},scopedSlots:_vm._u([{key:"item.log",fn:function(ref){
        var item = ref.item;
return [_c('Transaction-Event',{attrs:{"log":item,"short":true}})]}},{key:"item.timestamp",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"my-2 text-left"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.moment(item.receipt.transaction.timestamp),'MM/DD h:mm:ss A'))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.moment(item.receipt.transaction.timestamp).fromNow()))])])]}},{key:"item.transactionHash",fn:function(ref){
        var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'transaction',"hash":item.receipt.transactionHash}})]}},{key:"item.blockNumber",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":'/block/' + item.receipt.blockNumber}},[_vm._v(_vm._s(item.receipt.blockNumber))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }