var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.holders,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.holderCount,"headers":_vm.headers,"footer-props":{
            itemsPerPageOptions: [10, 25, 100]
        },"item-key":"address"},on:{"update:options":_vm.onPagination},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'address',"hash":item.address,"withName":"true","withTokenName":true}})]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromWei")(item.amount,_vm.tokenDecimals, _vm.tokenSymbol))+" ")]}},{key:"item.share",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(item.share))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }