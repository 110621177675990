var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.tokens,"headers":_vm.headers,"sort-by":_vm.currentOptions.sortBy[0],"must-sort":true,"sort-desc":true,"server-items-length":_vm.tokenCount,"footer-props":{
            itemsPerPageOptions: [10, 25, 100]
        },"item-key":"address"},on:{"update:options":_vm.getTokens},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'nft',"hash":item.address}})]}},{key:"item.tokenName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tokenName)+" ")]}},{key:"item.tokenSymbol",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tokenSymbol)+" ")]}},{key:"item.tokenTotalSupply",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tokenTotalSupply ? parseInt(item.tokenTotalSupply).toLocaleString() : 'N/A')+" ")]}},{key:"item.tags",fn:function(ref){
        var item = ref.item;
return _vm._l((item.patterns),function(pattern,idx){return _c('v-chip',{key:idx,staticClass:"success mr-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.formatContractPattern(pattern))+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }