var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[((!_vm.user.plan || _vm.user.plan == 'free') && _vm.user.onboarded)?_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","text":"","type":"error"}},[_vm._v("Free plan users are limited to one workspace. "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.goToBilling()}}},[_vm._v("Upgrade")]),_vm._v(" to our Premium plan to create more.")])],1):_c('v-card-text',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"dense":"","text":"","type":"error"},domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}),_c('div',{staticClass:"mb-2"},[(_vm.isUsingSafari)?_c('v-alert',{staticClass:"my-2",attrs:{"type":"warning"}},[_vm._v(" Safari is preventing websites from making CORS requests to localhost. This will prevent you from connecting to a local blockchain. If you want to do so, you'll need to use another browser. If you want to connect to a remote chain, or are not using Safari, you can ignore this message. ")]):_vm._e(),_c('a',{attrs:{"id":"detectServers","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.detectNetwork()}}},[_vm._v("Detect Networks")]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_vm._v(" This will send a RPC request asking for a network ID to 127.0.0.1 on http and ws protocols on commonly used ports (7545, 8545 and 9545)."),_c('br'),_vm._v("The address will be displayed below if the request is successful. ")]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.detectedNetworks.length),expression:"detectedNetworks.length"}]},_vm._l((_vm.detectedNetworks),function(address,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(address)+" "),_c('a',{attrs:{"href":"#","id":("serverDetected-" + idx)},on:{"click":function($event){$event.preventDefault();_vm.rpcServer = address}}},[_vm._v("Use")])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.noNetworks),expression:"noNetworks"}]},[_vm._v(" No networks detected. If you were expecting something, make sure they are running on 7545, 8545 or 9545 and that your browser is not blocking requests to localhost (looking at you Brave & Safari 👀!). ")])],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createWorkspace(_vm.name, _vm.rpcServer)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":[function (v) { return !!v || 'Name is required'; }],"outlined":"","id":"workspaceName","label":"Name*","placeholder":"My Ethereum Project","hide-details":"auto","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":[
                    function (v) { return this$1.isUrlValid(v) || 'RPC needs to be a valid URL'; },
                    function (v) { return !!v || 'RPC server is required'; }
                ],"outlined":"","id":"workspaceServer","label":"RPC Server*","placeholder":"ws://localhost:8545","hide-details":"auto","required":""},model:{value:(_vm.rpcServer),callback:function ($$v) {_vm.rpcServer=$$v},expression:"rpcServer"}}),_c('v-select',{attrs:{"outlined":"","required":"","label":"Chain","items":_vm.availableChains,"hide-details":"auto"},model:{value:(_vm.chain),callback:function ($$v) {_vm.chain=$$v},expression:"chain"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"createWorkspace","loading":_vm.loading,"color":"primary","disabled":!_vm.valid,"type":"submit"}},[_vm._v("Create")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }