var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":_vm.balanceChanges.length <= 10,"headers":_vm.tableHeaders,"items":_vm.balanceChanges},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_c('small',[_c('Hash-Link',{attrs:{"type":'address',"hash":_vm.token,"withTokenName":true,"withName":true}})],1)]),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Unformatted Amounts"},model:{value:(_vm.unformatted),callback:function ($$v) {_vm.unformatted=$$v},expression:"unformatted"}})],1)]},proxy:true},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('Hash-Link',{attrs:{"type":'address',"hash":item.address}})]}},{key:"item.before",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromWei")(item.previousBalance,_vm.decimals[item.address], _vm.symbols[item.address], _vm.unformatted))+" ")]}},{key:"item.now",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fromWei")(item.currentBalance,_vm.decimals[item.address], _vm.symbols[item.address], _vm.unformatted))+" ")]}},{key:"item.change",fn:function(ref){
var item = ref.item;
return [(_vm.changeDirection(item.diff) > 0)?_c('span',{staticClass:"success--text"},[_vm._v(" +"+_vm._s(_vm._f("fromWei")(item.diff,_vm.decimals[item.address], _vm.symbols[item.address], _vm.unformatted))+" ")]):_vm._e(),(_vm.changeDirection(item.diff) === 0)?_c('span',[_vm._v("0")]):_vm._e(),(_vm.changeDirection(item.diff) < 0)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm._f("fromWei")(item.diff,_vm.decimals[item.address], _vm.symbols[item.address], _vm.unformatted))+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }