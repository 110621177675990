var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isPremium && _vm.justUpgraded)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v("You've been successfully upgraded to the Premium plan. It is currently being activated, and should be ready in about a minute. Thank you!")]):_vm._e(),(_vm.isPremium && _vm.justUpgraded)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v("Your Premium plan is now ready!")]):_vm._e(),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],attrs:{"dense":"","text":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Free Plan "),_c('v-spacer'),(!_vm.isPremium)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"primary","small":""}},[_vm._v("Current Plan")]):_vm._e()],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.plans.free),function(feature,idx){return _c('v-list-item',{key:("free-" + idx)},[(feature)?_c('v-list-item-icon',{staticClass:"mx-0 mr-1"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1):_vm._e(),_vm._v(" "+_vm._s(feature)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(feature.help)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")]):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(feature.help)+" ")])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Premium Plan - $20/month "),_c('v-spacer'),(_vm.isPremium)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"primary","small":""}},[_vm._v("Current Plan")]):_vm._e()],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.plans.premium),function(feature,idx){return _c('v-list-item',{key:idx},[(feature)?_c('v-list-item-icon',{staticClass:"mx-0 mr-1"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1):_vm._e(),_vm._v(" "+_vm._s(feature)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(feature.help)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")]):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(feature.help)+" ")])],1)}),1),_c('v-card-actions',{staticClass:"justify-center d-flex flex-column"},[(_vm.isPremium)?_c('v-btn',{attrs:{"loading":_vm.subscriptionButtonLoading,"color":"primary"},on:{"click":function($event){return _vm.openStripePortal()}}},[_vm._v("Manage Subscription")]):_c('v-btn',{attrs:{"loading":_vm.subscriptionButtonLoading,"color":"primary"},on:{"click":function($event){return _vm.subscribeToPlan('premium')}}},[_vm._v("Subscribe")])],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Custom Plan "),_c('v-spacer')],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.plans.custom),function(feature,idx){return _c('v-list-item',{key:("free-" + idx)},[_c('v-list-item-icon',{staticClass:"mx-0 mr-1"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1),(feature.href)?_c('a',{attrs:{"href":feature.href,"target":"_blank"}},[_vm._v(_vm._s(feature.message || feature))]):_c('span',[_vm._v(_vm._s(feature.message || feature))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(feature.help)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")]):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(feature.help)+" ")])],1)}),1),_c('v-card-actions',{staticClass:"justify-center d-flex flex-column"},[_c('v-btn',{attrs:{"color":"primary","href":'mailto:contact@tryethernal.com?subject=Custom+Ethernal+Subscription',"target":'blank'}},[_vm._v("Contact Us")]),_c('small',[_vm._v("Or ping @antoinedc on "),_c('a',{attrs:{"href":"https://discord.gg/jEAprf45jj","target":"_blank"}},[_vm._v("Discord")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_vm._v(" All plans include unlimited blocks/transactions/accounts synchronization, Ganache/Hardhat/Brownie integration, and all Ethernal features. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }